var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar_list",class:{ border_l: _vm.borderShow, 'p-15': _vm.type == 'three' }},[_c('div',{staticClass:"sidebar_title"},[_c('h2',{staticClass:"sidebar_title_t",on:{"click":_vm.goNavType}},[_vm._v(_vm._s(_vm.searchValue))]),_vm._t("input")],2),(_vm.type == 'one')?_c('div',{staticClass:"sidebar_one"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"sidebar_one_item"},[_c('div',{staticClass:"sidebar_one_l"},[_c('div',[_c('img',{attrs:{"src":item.novel_cover,"alt":item.novel_name}})])]),_c('div',{staticClass:"sidebar_one_m"},[_c('span',{staticClass:"copy_icon"}),_c('span',{staticClass:"font_w hover_c",on:{"click":function($event){return _vm.goChapterList(item.novel_id)}}},[_vm._v(" "+_vm._s(item.novel_name)+" ")]),(item.hot == 1)?_c('span',{staticClass:"hot"},[_vm._v("Hot")]):_vm._e(),(item.full == 1)?_c('span',{staticClass:"full"},[_vm._v("Full")]):_vm._e(),_c('p',{staticClass:"author"},[_c('span',{staticClass:"pen_icon"}),_c('span',[_vm._v(_vm._s(item.novel_author))])])]),(item.last_chapter_name)?_c('div',{staticClass:"sidebar_one_r p-15"},[_c('p',{staticClass:"hover_c",on:{"click":function($event){return _vm.goChapter(
              item.novel_id,
              item.chapter_id,
              item.last_chapter_name,
              item.novel_name
            )}}},[_vm._v(" "+_vm._s(item.last_chapter_name)+" ")])]):_vm._e(),(item.chapter_num)?_c('div',{staticClass:"sidebar_one_chapter"},[_c('p',[_c('span',[_vm._v(_vm._s(item.chapter_num)+" ")]),_vm._m(0,true)])]):_vm._e()])}),0):_vm._e(),(_vm.type == 'two')?_c('div',{staticClass:"sidebar_two"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"sidebar_two_item"},[_c('div',{staticClass:"sidebar_two_l",on:{"click":function($event){return _vm.goChapterList(item.novel_id)}}},[_c('span',{staticClass:"rightIcon"}),_c('span',{staticClass:"hover_c"},[_vm._v(_vm._s(item.novel_name))]),(item.is_hot == 1 && _vm.page !== 'ArticleList')?_c('span',{staticClass:"hot"},[_vm._v("Hot")]):_vm._e()]),(item.tag && _vm.page !== 'ArticleList')?_c('div',{staticClass:"sidebar_two_r p-15"},_vm._l((item.tag),function(childItem){return _c('span',{key:childItem.id,staticClass:"hover_c",on:{"click":function($event){return _vm.goTypeList(childItem.id, 2, childItem.name)}}},[_vm._v(" "+_vm._s(childItem.name)+", ")])}),0):_vm._e(),(_vm.page !== 'ArticleList')?_c('div',{staticClass:"sidebar_two_r p-15 hover_c",on:{"click":function($event){return _vm.goChapter(
            item.novel_id,
            item.chapter_id,
            item.last_chapter_name,
            item.novel_name
          )}}},[_vm._v(" "+_vm._s(item.last_chapter_name)+" ")]):_vm._e(),(item.time && _vm.page !== 'ArticleList')?_c('div',{staticClass:"sidebar_two_r2 p-15"},[_vm._v(" "+_vm._s(item.time)+" ")]):_vm._e()])}),0):_vm._e(),(_vm.type == 'four')?_c('div',{staticClass:"sidebar_four"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"sidebar_four_item"},[_c('div',{staticClass:"hover_c",on:{"click":function($event){return _vm.goTypeList(item.id, 2, item.name)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0):_vm._e(),(_vm.type == 'three')?_c('div',{staticClass:"sidebar_three"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"sidebar_three_item"},[_c('div',{staticClass:"sidebar_three_seria",class:{
          bgc_red: index == 0,
          bgc_green: index == 1,
          bgc_blue: index == 2,
        }},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"sidebar_three_text"},[_c('p',{staticClass:"f_15 sidebar_three_t hover_c",on:{"click":function($event){return _vm.goChapterList(item.novel_id)}}},[_vm._v(" "+_vm._s(item.novel_name)+" ")]),_c('p',{staticClass:"f_12"},_vm._l((item.tag),function(childItem){return _c('span',{key:childItem.id,staticClass:"hover_c",on:{"click":function($event){return _vm.goTypeList(childItem.id, 2, childItem.name)}}},[_vm._v(" "+_vm._s(childItem.name)+", ")])}),0)])])}),0):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',[_vm._v("Chapters")])])
}]

export { render, staticRenderFns }