<template>
  <div
    class="sidebar_list"
    :class="{ border_l: borderShow, 'p-15': type == 'three' }"
  >
    <div class="sidebar_title">
      <h2 class="sidebar_title_t" @click="goNavType">{{ searchValue }}</h2>
      <slot name="input"></slot>
    </div>
    <div class="sidebar_one" v-if="type == 'one'">
      <div class="sidebar_one_item" v-for="(item, index) in data" :key="index">
        <div class="sidebar_one_l">
          <div><img :src="item.novel_cover" :alt="item.novel_name" /></div>
        </div>
        <div class="sidebar_one_m">
          <span class="copy_icon"></span>
          <span class="font_w hover_c" @click="goChapterList(item.novel_id)">
            {{ item.novel_name }}
          </span>
          <span class="hot" v-if="item.hot == 1">Hot</span>
          <span class="full" v-if="item.full == 1">Full</span>
          <p class="author">
            <span class="pen_icon"></span>
            <span>{{ item.novel_author }}</span>
          </p>
        </div>
        <div class="sidebar_one_r p-15" v-if="item.last_chapter_name">
          <p
            class="hover_c"
            @click="
              goChapter(
                item.novel_id,
                item.chapter_id,
                item.last_chapter_name,
                item.novel_name
              )
            "
          >
            {{ item.last_chapter_name }}
          </p>
        </div>
        <div class="sidebar_one_chapter" v-if="item.chapter_num">
          <p>
            <span>{{ item.chapter_num }} </span><span><i>Chapters</i></span>
          </p>
        </div>
      </div>
    </div>
    <div class="sidebar_two" v-if="type == 'two'">
      <div class="sidebar_two_item" v-for="(item, index) in data" :key="index">
        <div class="sidebar_two_l" @click="goChapterList(item.novel_id)">
          <span class="rightIcon"></span>
          <span class="hover_c">{{ item.novel_name }}</span>
          <span class="hot" v-if="item.is_hot == 1 && page !== 'ArticleList'"
            >Hot</span
          >
        </div>
        <div
          class="sidebar_two_r p-15"
          v-if="item.tag && page !== 'ArticleList'"
        >
          <span
            class="hover_c"
            v-for="childItem in item.tag"
            :key="childItem.id"
            @click="goTypeList(childItem.id, 2, childItem.name)"
          >
            {{ childItem.name }},
          </span>
        </div>
        <div
          v-if="page !== 'ArticleList'"
          class="sidebar_two_r p-15 hover_c"
          @click="
            goChapter(
              item.novel_id,
              item.chapter_id,
              item.last_chapter_name,
              item.novel_name
            )
          "
        >
          {{ item.last_chapter_name }}
        </div>

        <div
          class="sidebar_two_r2 p-15"
          v-if="item.time && page !== 'ArticleList'"
        >
          {{ item.time }}
        </div>
      </div>
    </div>
    <div class="sidebar_four" v-if="type == 'four'">
      <div class="sidebar_four_item" v-for="(item, index) in data" :key="index">
        <div class="hover_c" @click="goTypeList(item.id, 2, item.name)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="sidebar_three" v-if="type == 'three'">
      <div
        class="sidebar_three_item"
        v-for="(item, index) in data"
        :key="index"
      >
        <div
          class="sidebar_three_seria"
          :class="{
            bgc_red: index == 0,
            bgc_green: index == 1,
            bgc_blue: index == 2,
          }"
        >
          {{ index + 1 }}
        </div>
        <div class="sidebar_three_text">
          <p
            class="f_15 sidebar_three_t hover_c"
            @click="goChapterList(item.novel_id)"
          >
            {{ item.novel_name }}
          </p>
          <p class="f_12">
            <span
              class="hover_c"
              v-for="childItem in item.tag"
              :key="childItem.id"
              @click="goTypeList(childItem.id, 2, childItem.name)"
            >
              {{ childItem.name }},
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import penIcon from "@/assets/img/pen.png";
import copyIcon from "@/assets/img/copy.png";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    borderShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    type: {
      type: String,
      default: () => {
        return null;
      },
    },
    searchValue: {
      type: String,
      default: () => {
        return "";
      },
    },
    page: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return { penIcon: penIcon, copyIcon: copyIcon };
  },
  methods: {
    // 去列表详情
    goNavType() {
      this.$emit("onNavList");
    },
    // 去分类列表
    goTypeList(key, type, name) {
      this.$emit("onTypeList", key, type, name);
    },
    // 去小说详情
    goChapterList(id) {
      if (this.page == "ArticleList") {
        this.$emit("onChapterList", id);
      } else {
        this.$router.push({
          path: `/articleList/${id}`,
        });
      }
    },
    // 去小说章节
    goChapter(nid, cid, last_name, name) {
      let arrayLocal = JSON.parse(localStorage.getItem("historyList"));
      if (arrayLocal) {
        let isTrue = arrayLocal.some((item) => item.novel_id == nid);
        if (isTrue) {
          arrayLocal.forEach((item) => {
            if (item.novel_id == nid) {
              item.last_chapter_name = last_name;
              item.chapter_id = cid;
            }
          });
        } else {
          arrayLocal.unshift({
            novel_id: nid,
            novel_name: name,
            last_chapter_name: last_name,
            chapter_id: cid,
          });
        }
      } else {
        arrayLocal = [];
        arrayLocal.unshift({
          novel_id: nid,
          novel_name: name,
          last_chapter_name: last_name,
          chapter_id: cid,
        });
      }

      localStorage.setItem("historyList", JSON.stringify(arrayLocal));
      this.$router.push({
        path: `/article/${nid}/${cid}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar_list {
  width: 100%;

  .sidebar_title {
    cursor: default;
    height: 40px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    position: relative;
    font-size: 20px;

    span,
    h2 {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #4e4e4e;
    }
  }

  .sidebar_one {
    .sidebar_one_item {
      min-height: 90px;
      border-bottom: 1px dashed #ccc;
      display: table;

      // div {
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      .sidebar_one_l {
        padding: 0 10px;
        display: table-cell;
        vertical-align: middle;
        div {
          width: 182px;
          height: 82px;
          overflow: hidden;
          img {
            object-fit: cover;
            display: block;
            width: 100%;
            height: 100%;
            // width: 182px;
            // height: 82px;
          }
        }
      }

      .sidebar_one_m {
        color: #3c3c3c;
        width: 100%;
        display: table-cell;
        padding: 0 10px;
        vertical-align: middle;

        .copy_icon {
          position: relative;
          margin-right: 20px;

          &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/copy.png") center center no-repeat;
            background-size: 100% 100%;
            width: 14px;
            height: 14px;
          }
        }

        .pen_icon {
          position: relative;
          margin-right: 20px;

          &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/pen.png") center center no-repeat;
            background-size: 100% 100%;
            width: 14px;
            height: 14px;
          }
        }

        .author {
          font-style: italic;
          display: block;
        }

        .gray {
          color: #4e4e4e;
        }

        .font_w {
          color: #3c3c3c;
          font-weight: bold;
          font-size: 18px;
        }

        .hot {
          border: 1px solid #fd8383;
          color: #fd8383;
          padding: 1px 3px;
          font-size: 13px;
          vertical-align: bottom;
          margin-left: 5px;
        }

        .full {
          border: 1px solid #86ad86;
          color: #86ad86;
          padding: 1px 3px;
          font-size: 13px;
          vertical-align: bottom;
          margin-left: 5px;
        }
      }
      .sidebar_one_chapter {
        max-width: 150px;
        min-width: 150px;
        color: #14425d;
        font-weight: 600;
        padding: 0 10px;
        vertical-align: middle;
        display: table-cell;
        p {
          word-wrap: break-word;
          line-height: 20px;
          white-space: normal;
          i {
            color: #31708f;
            font-weight: normal;
          }
        }
      }
      .sidebar_one_r {
        max-width: 110px;
        min-width: 110px;
        color: #14425d;
        padding: 0 10px;
        vertical-align: middle;
        display: table-cell;

        p {
          word-wrap: break-word;
          line-height: 20px;
          white-space: normal;
        }
      }

      &:last-child {
        border-bottom: 1px dashed transparent;
      }
    }
  }

  .sidebar_two {
    .sidebar_two_item {
      border-bottom: 1px dashed #ccc;
      display: flex;

      div {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 40px;
      }

      .hot {
        border: 1px solid #fd8383;
        color: #fd8383;
        padding: 1px 3px;
        font-size: 13px;
        vertical-align: bottom;
        margin-left: 5px;
      }

      .sidebar_two_l {
        flex: 1;
        .rightIcon {
          position: relative;
          margin-right: 16px;

          &::after {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            content: " ";
            background: url("~@/assets/img/backR.png") center center no-repeat;
            background-size: 100% 100%;
            width: 14px;
            height: 14px;
          }
        }
      }

      .sidebar_two_r {
        border-left: 1px dashed #ccc;
        width: 35%;
      }
      .sidebar_two_r2 {
        border-left: 1px dashed #ccc;
        width: 15%;
      }
      &:last-child {
        border-bottom: 1px dashed transparent;
      }
    }
  }

  .sidebar_four {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .sidebar_four_item {
      height: 30px;
      line-height: 30px;
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sidebar_three {
    .sidebar_three_item {
      padding: 0 10px;
      height: 50px;
      border-bottom: 1px dashed #ccc;
      display: flex;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .sidebar_three_text {
        flex: 1;
        line-height: 25px;
        color: #3c3c3c;

        .sidebar_three_t {
          color: #083767;
        }
      }

      .sidebar_three_seria {
        border: #cbcbcb solid 1px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-top: 10px;
        text-align: center;
        color: #4e4e4e;
        margin-right: 10px;
      }

      .bgc_red {
        color: #fff;
        background-color: #e74c3c;
        border-color: #e74c3c;
      }

      .bgc_green {
        color: #fff;
        background-color: #5eb949;
        border-color: #5eb949;
      }

      .bgc_blue {
        color: #fff;
        background-color: #5cabb8;
        border-color: #5cabb8;
      }

      &:last-child {
        border-bottom: 1px dashed transparent;
      }
    }
  }
}

.p-15 {
  padding: 0 15px;
}

.f_15 {
  font-size: 15px;
}

.f_12 {
  font-size: 12px;
}

.f_18 {
  font-size: 18px;
}

.border_l {
  padding: 0 15px;
  background-color: #ecf0f1;
  border: 1px solid #d9e1e4;
}
</style>